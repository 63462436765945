import { NewsPostGaudi, NewsPostLocaleContent } from 'Shared/types/news';

type Params = {
  allNewsPosts: NewsPostLocaleContent;
  slug: string;
  date: string;
  count: number;
  userId: string;
  projectName: string;
  includeSentEmailCount: boolean;
};

/**
 * Returns a gaudi news post (as saved in the gaudi db)
 * with the content from all locales appended below each other.
 * Currently returning froala-formatted content.
 */
export const makeNewsEntriesForGaudi = ({
  allNewsPosts,
  slug,
  date,
  count,
  userId,
  projectName,
  includeSentEmailCount,
}: Params): NewsPostGaudi => {
  const engNewsItem = allNewsPosts['en-GB'];
  let appendedContent = '';
  const gaudiNewsPost = {
    acorn: true,
    project: projectName,
    user_id: userId,
    slug,
    date,
    sentUpdateEmailCount: {
      count,
      date,
      user_id: userId,
    },
    by: engNewsItem.author,
    copy: '',
    headline: engNewsItem.headline,
  };
  Object.keys(allNewsPosts).forEach((locale: string, index: number) => {
    const localeNewsItem = allNewsPosts[locale];
    if (index === 0) {
      appendedContent = localeNewsItem.content;
    } else {
      appendedContent =
        appendedContent + '<p>------------</p>' + localeNewsItem.content;
    }
  });
  gaudiNewsPost.copy = `<div>${appendedContent}</div>`;

  if (!includeSentEmailCount) delete gaudiNewsPost.sentUpdateEmailCount;
  return gaudiNewsPost;
};
