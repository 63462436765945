import * as React from 'react';
import dynamic from 'next/dynamic';
import { Spinner } from 'Client/pages/thanks/components/Stats.style';

export const NoSSRFroalaContentEditor = dynamic(
  () => import('./FroalaContentEditor').then((mod) => mod.FroalaContentEditor),
  {
    loading: function Loading() {
      return <Spinner />;
    },
    ssr: false,
  }
);
