import { darken, opacify } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 2rem;
  margin-bottom: 6rem;
`;

export const FlexWrapper = styled.div<{
  gapInRem: number;
  direction: 'column' | 'row';
}>`
  display: flex;
  gap: ${({ gapInRem }) => gapInRem}rem;
  flex-direction: ${({ direction }) => direction};
`;

export const formTitleStyles = css`
  color: ${({ theme }) => theme.colors.black[500]};
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin: 0;
`;

export const NewsFormWrapper = styled.div`
  display: flex;
  padding: 1rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  border-radius: 0.438rem;
  border: 0.05rem solid
    ${({ theme }) => opacify(-0.95, theme.colors.black[500])};
  background: ${({ theme }) => theme.colors.white[500]};
  box-shadow: 0 0 1.5rem 0
    ${({ theme }) => opacify(-0.9, theme.colors.black[500])};
  max-width: 40.875rem;

  h3 {
    ${formTitleStyles}
    font-size: 1rem;
  }

  & > h2 {
    color: ${({ theme }) => theme.colors.black[500]};
  }
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.black[500]};
  font-size: 2rem;
  margin-bottom: 1.5rem;
  margin-top: 0;
`;

export const ErrorMessage = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.semi};
  color: ${({ theme }) => theme.colorMappings.error};
`;

export const BannerFlag = styled.div`
  min-height: 4.25rem;
  width: 0.313rem;
  background-color: ${({ theme }) => theme.colorMappings.warningDark};
`;

export const BannerContent = styled.div`
  padding: 1rem;
  font-size: 0.875rem;
  background: ${({ theme }) => theme.colorMappings.warningLight};
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
  max-width: 40.5rem;
  padding-right: 9.8rem;
  color: ${({ theme }) => theme.colors.black[500]};

  p {
    margin: 0;

    span {
      font-weight: bold;
    }
  }
`;

export const WarningBanner = styled.div`
  min-height: 4.25rem;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
`;

export const ButtonBase = styled.button`
  padding: 0.625rem 1.25rem;
  border-radius: 0.234rem;
  display: inline-flex;
  align-items: center;
  gap: 0.625rem;
  cursor: pointer;
`;

export const PublishButton = styled(ButtonBase)`
  ${({ theme }) => css`
    border: none;
    background: ${theme.colors.green['A700']};
    color: ${theme.colors.white[500]};
    text-align: center;
    font-family: ${theme.fontFamily};
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;

    &:hover {
      background-color: ${darken(0.1, theme.colors.green['A700'])};
    }

    &:disabled {
      opacity: 0.4;
      background: ${theme.colors.green['A700']};
      cursor: unset;
    }
  `}
`;

export const DeleteButton = styled(ButtonBase)`
  ${({ theme }) => css`
    border: ${`0.0625rem solid ${theme.colorMappings.removeButtonBorder}`};
    background: ${theme.colorMappings.mapCancelButtonBackground};
    color: ${theme.colors.white[500]};
    text-align: center;
    font-family: ${theme.fontFamily};
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;

    &:hover {
      border-color: ${darken(
        0.1,
        theme.colorMappings.mapCancelButtonBackground
      )};
      color: ${darken(0.1, theme.colors.white[500])};
    }

    &:disabled {
      border-color: ${theme.colorMappings.greyButtonBorder};
      color: ${theme.colorMappings.greyButtonLabel};
      opacity: 0.4;
      cursor: unset;
    }
  `}
`;

export const PublishWithoutEmailButton = styled(ButtonBase)`
  ${({ theme }) => css`
    border: ${`0.0625rem solid ${theme.colorMappings.greyButtonBorder}`};
    background: ${theme.colors.white[500]};
    color: ${theme.colorMappings.greyButtonLabel};
    text-align: center;
    font-family: ${theme.fontFamily};
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;

    &:hover {
      border-color: ${theme.colorMappings.greyButtonBorderHover};
      color: ${theme.colorMappings.greyButtonLabelHover};
    }

    &:disabled {
      border-color: ${theme.colorMappings.greyButtonBorder};
      color: ${theme.colorMappings.greyButtonLabel};
      opacity: 0.4;
      cursor: unset;
    }
  `}
`;

export const BackButton = styled(ButtonBase)`
  border: 0.125rem solid ${({ theme }) => theme.colors.green['A700']};
  background: ${({ theme }) => theme.colors.white[500]};

  span {
    color: ${({ theme }) => theme.colors.green['A700']};
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25rem;
  }

  &:hover {
    background: ${({ theme }) => opacify(-0.9, theme.colors.green['A700'])};
  }
`;

export const RightSideWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
`;
