import { gql } from '@apollo/client';

export const ADD_NEWS_TILE_IMAGE = gql`
  mutation AddTileImage($input: AddTileImageInput) {
    addTileImage(input: $input) {
      status
      message
    }
  }
`;

export const GET_NEWS_FROM_SLUG = gql`
  query GetNewsBySlug($slug: String, $projectId: String) {
    getNewsBySlug(slug: $slug, projectId: $projectId) {
      message
      status
      news {
        _id
      }
    }
  }
`;
