import * as React from 'react';
import { IconProps } from '../types';

export const ArrowBack: React.FC<IconProps> = ({
  width = 20,
  height = 20,
  fill = '#00A863',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.97557 4.32457C9.31676 4.66575 9.31676 5.21892 8.97557 5.5601L5.7016 8.83408L16.3001 8.83408C16.7826 8.83408 17.1738 9.22523 17.1738 9.70774C17.1738 10.1902 16.7826 10.5814 16.3001 10.5814L5.7016 10.5814L8.97557 13.8554C9.31676 14.1965 9.31676 14.7497 8.97557 15.0909C8.63439 15.4321 8.08122 15.4321 7.74004 15.0909L2.97464 10.3255C2.63345 9.98432 2.63345 9.43115 2.97464 9.08997L7.74004 4.32457C8.08122 3.98338 8.63439 3.98338 8.97557 4.32457Z"
        fill={fill}
      />
    </svg>
  );
};
