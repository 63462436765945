import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 4.25rem;
  display: flex;
  flex-direction: row;
`;

export const Flag = styled.div`
  min-width: 0.313rem;
  background-color: ${({ theme }) => theme.colorMappings.focusedInputBorder};
`;
export const Content = styled.div`
  background-color: ${({ theme }) =>
    theme.colorMappings.systemMessageBackground};
  padding: 1rem 1.313rem 1.188rem 1.813rem;
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.black[500]};

  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 1rem;
  margin: 0;
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.black[500]};

  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
`;
