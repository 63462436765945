import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'Client/components/molecules/TextInput/TextInput';
import { ImageUpload } from 'Client/components/molecules/ImageUpload/ImageUpload';
import { SystemMessage } from 'Client/components/molecules/SystemMessage/SystemMessage';
import { FlexWrapper, NewsFormWrapper } from '../../AddNewsPage.styles';
import { NewsImageFormProps } from '../../types';

export const NewsImageForm: React.FC<NewsImageFormProps> = ({
  setImage,
  setAltText,
  altText,
  image,
}) => {
  const { t } = useTranslation('customer');
  return (
    <NewsFormWrapper>
      <FlexWrapper direction="column" gapInRem={1}>
        <FlexWrapper direction="column" gapInRem={0.5}>
          <h3>{t('News tile image')}</h3>
          <SystemMessage
            title=""
            message={t(
              'This image will appear as the main image in your news list. To ensure optimal result, please ensure the image you upload will have a 1:1 ratio (square) and that it will  have a minimum size of 800 pixels.'
            )}
          />
        </FlexWrapper>
        <ImageUpload
          setImage={setImage}
          image={image}
          respondentSide={false}
          fallbackWidth={400}
        />
        <TextInput
          label={t('Alt text')}
          placeholder={t('Add a description to your image')}
          gap={0.25}
          onChange={(e) => setAltText(e.currentTarget.value)}
          value={altText}
        />
      </FlexWrapper>
    </NewsFormWrapper>
  );
};
