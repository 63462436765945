import { NewsPostLocaleContent } from 'Shared/types/news';

export const validateAllFieldsHaveValues = (
  allNews: NewsPostLocaleContent
): boolean => {
  let isValid = true;
  Object.keys(allNews).forEach((locale) => {
    const localeNewsItem = allNews[locale];
    Object.keys(localeNewsItem).forEach((key) => {
      if (localeNewsItem[key] === '') {
        // as soon as one value is empty, return
        isValid = false;
        return;
      }
      return;
    });
    return;
  });
  return isValid;
};
