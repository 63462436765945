import * as React from 'react';
import { Content, Flag, Wrapper, Title, Text } from './SystemMessage.styles';
import { SystemMessagesProps } from './types';

export const SystemMessage: React.FC<SystemMessagesProps> = ({
  message,
  title,
}) => {
  return (
    <Wrapper>
      <Flag />
      <Content>
        <Title>{title}</Title>
        <Text>{message}</Text>
      </Content>
    </Wrapper>
  );
};
